import * as React from 'react'
import { Link } from 'gatsby'
import Layout from "../components/common/layout"
import { Helmet } from 'react-helmet'
const NotFoundPage = () => {
  return (
    <Layout>
      <section className='title-block text-center tpl-block block-not-found'   >
            <div className='container'>
                <h2>404 Not Found</h2>
                <div className='title-block-text' >
                  <p>
                  This is somewhat embarrassing, isn’t it? <br/>
                    It seems we can’t find what you’re looking for.
                  </p>
                </div>
            </div> 
        </section> 
    </Layout>
  )
}

export default NotFoundPage
